import { useSeoMeta } from "@unhead/vue";
import { useRuntimeConfig } from 'nuxt/app'

export function useCustomSeoMeta (sbMetadata) {
  const config = useRuntimeConfig();
  useSeoMeta({
    title: sbMetadata.og_title,
    ogSiteName: config.public["brandName"],
    ogTitle: sbMetadata.og_title,
    description: sbMetadata.og_description,
    ogDescription: sbMetadata.og_description,
    ogImage: sbMetadata.og_image?.filename || "",
    twitterCard: sbMetadata.og_image?.filename || "",
    robots: sbMetadata.noindex_nofollow ? "noindex" : "all",
  });
}
